<template>
    <div class="content">
        <BreadCrumbs
            :elements="[
                { link: 'Profile', name: 'Profil' },
                { link: 'MyOrders', name: 'Megrendeléseim' }
            ]"
        />
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="title text-center">Megrendeléseim</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="mb-5">
            <div class="container">
                <div
                    class="row align-items-center border-bottom py-3 d-none d-md-flex"
                >
                    <div class="col-md-6">
                        <strong class="text-uppercase"
                            >Megrendelés azonosító</strong
                        >
                    </div>
                    <div class="col-md-2">
                        <strong class="text-uppercase">Státusz</strong>
                    </div>
                    <div class="col-md-2">
                        <strong class="text-uppercase">Dátum</strong>
                    </div>
                    <div class="col-md-2">
                        <strong class="text-uppercase">Összeg</strong>
                    </div>
                </div>
                <template v-for="(item, index) in model">
                    <div
                        class="row align-items-center border-bottom py-3 cursor-pointer"
                        v-bind:key="'orders-header-' + index"
                        v-on:click="toggleCollapse(index)"
                    >
                        <div class="col col-md-6">
                            <strong class="text-uppercase text-orange">{{
                                item.orderid
                            }}</strong>
                        </div>
                        <div class="col col-md-2">
                            Teljesítve
                        </div>
                        <div class="col col-md-2">
                            {{ item.created_at }}
                        </div>
                        <div class="col col-md-2">{{ item.price }}</div>
                    </div>
                    <b-collapse
                        :id="'accordion-' + index"
                        v-bind:key="'orders-collapse-' + index"
                        accordion="my-accordion"
                        role="tabpanel"
                    >
                        <b-card-body>
                            <div
                                class="row align-items-center border-bottom py-3"
                            >
                                <div class="col col-md-6">
                                    <strong class="text-uppercase"
                                        >Termék neve</strong
                                    >
                                </div>
                                <div class="col col-md-2">
                                    <strong class="text-uppercase"
                                        >Ár/Db</strong
                                    >
                                </div>
                                <div class="col col-md-2">
                                    <strong class="text-uppercase"
                                        >Mennyiség</strong
                                    >
                                </div>
                                <div class="col col-md-2">
                                    <strong class="text-uppercase"
                                        >Részösszeg</strong
                                    >
                                </div>
                            </div>
                            <div
                                class="row align-items-center border-bottom py-3"
                                v-for="(item2, index2) in item.items"
                                v-bind:key="'prod-' + index + '-' + index2"
                            >
                                <div class="col col-md-6">
                                    <strong class="text-uppercase"
                                        >{{ item2.product.cicsopnev }} <br />
                                        {{ item2.product.cikknev }} <br />
                                        {{ item2.product.pattern }} <br />
                                        {{ item2.product.m1nev }}</strong
                                    >
                                </div>
                                <div class="col col-md-2">
                                    {{ item2.price }} Ft
                                </div>
                                <div class="col col-md-2">
                                    {{ item2.qty }} db
                                </div>
                                <div class="col col-md-2">
                                    {{ item2.price * item2.qty }} Ft
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </template>
            </div>
        </section>
    </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import ApiService from "@/core/services/api.service";
export default {
    name: "MyOrders",
    components: { BreadCrumbs },
    data() {
        return {
            model: []
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_DIOS_URL;
        }
    },
    beforeMount() {
        this.getMyOrders();
    },
    methods: {
        toggleCollapse(id) {
            this.$root.$emit("bv::toggle::collapse", "accordion-" + id);
        },
        getMyOrders() {
            ApiService.get(`${this.url}/my-orders`).then(response => {
                this.model = response.data.data;
            });
        }
    }
};
</script>

<style scoped></style>
