<template>
    <div class="content">
        <BreadCrumbs
            :elements="[
                { link: 'Profile', name: 'Profil' },
                { link: 'ProfileData', name: 'Személyes adataim' }
            ]"
        />
        <section class="py-5" v-if="model && model.shipping && model.billing">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <h1 class="title text-center">Személyes adataim</h1>
                        <form v-on:submit.prevent="updateProfile">
                            <div class="my-4">
                                <label class="d-block">
                                    <input
                                        type="radio"
                                        name="usertype"
                                        :value="false"
                                        v-model="isCompany"
                                        checked
                                    />
                                    Magánszemélyként vásárolok
                                </label>
                                <label class="d-block">
                                    <input
                                        type="radio"
                                        name="usertype"
                                        :value="true"
                                        v-model="isCompany"
                                    />
                                    Cégként vásárolok
                                </label>
                            </div>
                            <div class="my-4">
                                <label>Név*</label>
                                <input
                                    type="text"
                                    name="name"
                                    v-model="model.shipping.name"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4" v-if="isCompany">
                                <label>Cégnév*</label>
                                <input
                                    type="text"
                                    name="company_name"
                                    v-model="model.billing.name"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4" v-if="isCompany">
                                <label>Adószám*</label>
                                <input
                                    type="text"
                                    name="vat_number"
                                    v-model="model.billing.vat_number"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4">
                                <label>Telefonszám*</label>
                                <input
                                    type="text"
                                    name="phone"
                                    v-model="model.shipping.phone"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4">
                                <label>E-mail*</label>
                                <input
                                    type="email"
                                    name="email"
                                    v-model="model.email"
                                    class="form-control"
                                />
                            </div>
                            <div class="title-small mb-4">
                                Számlázási adataim
                            </div>
                            <div class="my-4">
                                <label>Ország*</label>
                                <input
                                    type="text"
                                    name="country"
                                    v-model="model.billing.country"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4">
                                <label>Irányítószám*</label>
                                <input
                                    type="text"
                                    name="zip"
                                    v-model="model.billing.zip"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4">
                                <label>Város*</label>
                                <input
                                    type="text"
                                    name="city"
                                    v-model="model.billing.city"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4">
                                <label>Utca/Házszám*</label>
                                <input
                                    type="text"
                                    name="address"
                                    v-model="model.billing.address"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4">
                                <label class="d-block">
                                    <input
                                        type="checkbox"
                                        name="usertype"
                                        :value="1"
                                        v-model="isSameShipping"
                                        v-on:change="changeSameShip"
                                        checked
                                    />
                                    Szállítási címem megegyezik a számlázási
                                    címemmel.
                                </label>
                            </div>
                            <template v-if="!isSameShipping">
                                <div class="my-4">
                                    <label>Ország*</label>
                                    <input
                                        type="text"
                                        name="shipping_country"
                                        v-model="model.shipping.country"
                                        class="form-control"
                                    />
                                </div>
                                <div class="my-4">
                                    <label>Irányítószám*</label>
                                    <input
                                        type="text"
                                        name="shipping_zip"
                                        v-model="model.shipping.zip"
                                        class="form-control"
                                    />
                                </div>
                                <div class="my-4">
                                    <label>Város*</label>
                                    <input
                                        type="text"
                                        name="shipping_city"
                                        v-model="model.shipping.city"
                                        class="form-control"
                                    />
                                </div>
                                <div class="my-4">
                                    <label>Utca/Házszám*</label>
                                    <input
                                        type="text"
                                        name="shipping_address"
                                        v-model="model.shipping.address"
                                        class="form-control"
                                    />
                                </div>
                            </template>
                            <div class="title-small mb-4">
                                Jelszó szerkesztése
                            </div>
                            <div class="my-4">
                                <label>Új jelszó</label>
                                <input
                                    type="password"
                                    name="new_password"
                                    v-model="model.password"
                                    class="form-control"
                                />
                            </div>
                            <div class="my-4">
                                <label>Új jelszó újra</label>
                                <input
                                    type="password"
                                    name="new_password_confirmation"
                                    v-model="model.password_confirm"
                                    class="form-control"
                                />
                            </div>
                            <div class="row">
                                <div class="col-md-8"></div>
                                <div class="col-md-4 text-end">
                                    <button
                                        type="submit"
                                        class="btn btn-primary w-100 mb-2"
                                    >
                                        Mentés
                                    </button>
                                    <p class="text-mini text-grey">
                                        A *-al jelölt mezők kitöltése kötelező
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BreadCrumbs from "@/components/BreadCrumbs";

export default {
    name: "ProfileData",
    components: { BreadCrumbs },
    data() {
        return {
            model: {
                billing: {
                    country: "Magyarország"
                },
                shipping: {
                    country: "Magyarország"
                }
            },
            isCompany: false,
            isSameShipping: true
        };
    },
    beforeMount() {
        if ($cookies.get("id_token") && $cookies.get("user")) {
            this.getUserData();
        }
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_DIOS_URL;
        }
    },
    methods: {
        changeSameShip() {
            if (this.isSameShipping) {
                this.model.shipping.country = this.model.billing.country;
                this.model.shipping.city = this.model.billing.city;
                this.model.shipping.zip = this.model.billing.zip;
                this.model.shipping.street = this.model.billing.street;
                this.model.shipping.street_type = this.model.billing.street_type;
                this.model.shipping.street_number = this.model.billing.street_number;
                this.model.shipping.floor = this.model.billing.floor;
                this.model.shipping.door = this.model.billing.door;
                this.model.shipping.helyrajzi_szam = this.model.billing.helyrajzi_szam;
            }
        },
        getUserData() {
            const self = this;
            ApiService.get(`${self.url}/user/profile`).then(response => {
                self.model = response.data.data;
                if (
                    self.model.shipping.country ===
                        self.model.billing.country &&
                    self.model.shipping.zip === self.model.billing.zip &&
                    self.model.shipping.city === self.model.billing.city &&
                    self.model.shipping.address === self.model.billing.address
                ) {
                    self.isSameShipping = true;
                } else {
                    self.isSameShipping = false;
                }

                if (self.model.billing.vat_number) {
                    self.isCompany = true;
                }
            });
        },
        updateProfile() {
            ApiService.put(
                `${this.url}/user/update-frontend-profile`,
                this.model
            );

            if (
                this.model.password &&
                this.model.password_confirm &&
                this.model.password !== "" &&
                this.model.password_confirm !== ""
            ) {
                ApiService.put(`${this.url}/user/change-password`, this.model);
            }
        }
    }
};
</script>

<style scoped></style>
