<template>
    <div class="content">
        <BreadCrumbs :elements="[{ link: 'Profile', name: 'Profil' }]" />
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="title text-center">Profil</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-7">
                        <router-link
                            :to="{ name: 'ProfileData' }"
                            class="text-black d-block mb-5"
                        >
                            <strong>Személyes adataim</strong>
                            <p>
                                Lehetővé teszi, hogy módosítsd a fiók adatait,
                                szállítási és számlázási címeidet, valamint
                                jelszavadat.
                            </p>
                        </router-link>

                        <router-link
                            :to="{ name: 'MyOrders' }"
                            class="text-black d-block my-5"
                        >
                            <strong>Vásárlásaim</strong>
                            <p>Kövesd nyomon a megrendeléseidet.</p>
                        </router-link>

                        <router-link
                            :to="{ name: 'ProfileNewsletter' }"
                            class="text-black d-block my-5"
                        >
                            <strong>Profil kezelése</strong>
                            <p>
                                Itt kezelheted a feliratkozásaidat és törölheted
                                a felhasználói fiókod.
                            </p>
                        </router-link>
                        <a
                            href="#"
                            v-on:click.prevent="logout"
                            class="text-black d-block my-5"
                        >
                            <strong>Kijelentkezés</strong>
                        </a>
                    </div>
                    <div class="col-md-5">
                        <img src="/img/profile.png" class="w-100" />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
export default {
    name: "Profile",
    components: { BreadCrumbs },
    methods: {
        logout() {
            $cookies.remove("user");
            $cookies.remove("id_token");
            document.location.href = "/";
        }
    }
};
</script>

<style scoped></style>
