<template>
    <div class="content">
        <BreadCrumbs
            :elements="[
                { link: 'Profile', name: 'Profil' },
                { link: 'ProfileNewsletter', name: 'Profil kezelése' }
            ]"
        />
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <h1 class="title text-center">Profil kezelése</h1>
                        <form>
                            <div class="title-small my-4">
                                Leiratkozás a hírlevélről
                            </div>
                            <div class="my-4">
                                <label>Feliratkozott e-mail cím</label>
                                <input
                                    type="email"
                                    name=""
                                    class="form-control"
                                />
                            </div>
                            <div class="row">
                                <div class="col-md-4 offset-md-8 text-end">
                                    <button
                                        type="submit"
                                        class="btn btn-primary w-100 mb-2"
                                    >
                                        Leiratkozás
                                    </button>
                                </div>
                            </div>
                        </form>
                        <form v-on:submit.prevent="deleteProfile">
                            <div class="title-small my-4">
                                Felhasználói fiók törlése
                            </div>
                            <p>
                                Felhasználói fiókod törlésével minden adatot és
                                korábbi rendelésed törlésre kerül.
                            </p>
                            <div class="row">
                                <div class="col-md-4 offset-md-8 text-end">
                                    <button
                                        type="submit"
                                        class="btn btn-third w-100 mb-2"
                                    >
                                        Fiók törlése
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import ApiService from "@/core/services/api.service";

export default {
    name: "ProfileNewsletter",
    components: { BreadCrumbs },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_DIOS_URL;
        }
    },
    methods: {
        deleteProfile() {
            var r = confirm("Biztosan törölni szeretné a profilját?");

            if (r !== false) {
                ApiService.delete(`${this.url}/user/delete-profile`).then(
                    () => {
                        $cookies.remove("user");
                        $cookies.remove("id_token");
                        document.location.href = "/";
                    }
                );
            }
        }
    }
};
</script>

<style scoped></style>
